import React, { useEffect, useState } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import history from './history';
import { checkAuth } from './actions/auth';
import Cookies from 'js-cookie';

import Streamer from './views/Streamer';
import Viewer from './views/Viewer';
import Login from './views/Login';
import Admin from './views/Admin';
import Charts from './views/Charts';
import BaseSnackbar from './components/BaseSnackbar';

import 'semantic-ui-css/semantic.min.css';
import styles from './App.module.scss';

const App = () => {
  const auth = useSelector((state) => state.auth);

  return (
    <div className={styles.app}>
      <Router history={history}>
        <Switch>
          <Route
            path="/"
            exact
            render={() => (auth.token ? <Streamer /> : <Redirect to="/login" />)}
          />
          <Route path="/login" component={Login} />
          <Route
            path="/viewer"
            render={() => (auth.token ? <Viewer /> : <Redirect to="/login" />)}
          />
          <Route
            path="/admin"
            render={() => (auth.isAdmin ? <Admin /> : <Redirect to="/login" />)}
          />
          <Route
            path="/chart"
            render={() => (auth.token ? <Charts /> : <Redirect to="/admin" />)}
          />
        </Switch>
      </Router>
      <BaseSnackbar />
    </div>
  );
};

export default App;
