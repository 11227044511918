import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(2),
      padding: theme.spacing(2),
      width: theme.spacing(32),
      height: theme.spacing(16),
    },
    '& h2': {
      fontWeight: 500,
      fontSize: '16px',
      color: '#666',
    },
    '& h3': {
      fontWeight: 500,
      fontSize: '20px',
      color: '#333',
      marginTop: '30px',
    },
    '& span': {
      fontWeight: 500,
      fontSize: '18px',
    },
  },
}));

const BasePaper = ({ title, content, unit }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={4}>
        <h2>{title}</h2>
        <h3>
          {content}
          <span>{unit}</span>
        </h3>
      </Paper>
    </div>
  );
};

export default BasePaper;
