import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomerIntervalBandwidth, fetchCustomerTotalStats } from '../../actions/customer';

import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import BandwidthChart from '../../components/BandwidthChart';
import CopyBoard from '../../components/CopyBoard';
import AdminTotalStats from '../../components/AdminTotalStats';
import BasePaper from '../../components/BasePaper';
import Navbar from '../../components/Navbar';

import styles from './index.module.scss';

const CustomerCharts = () => {
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY/MM/DD'));

  const dispatch = useDispatch();

  const { isAdmin, token } = useSelector((state) => state.auth);
  const { selectedCustomer } = useSelector((state) => state.customer);
  const { intervalBandwidthOfSelectedCustomer, intervalTotalStatsOfSelectedCustomer } = useSelector(
    (state) => state.customer
  );

  useEffect(async () => {
    dispatch(
      fetchCustomerIntervalBandwidth({
        from: dayjs(selectedDate).valueOf(),
        to: dayjs(selectedDate).add(1, 'day').valueOf(),
        interval: 3600000,
      })
    );
    dispatch(fetchCustomerTotalStats());
  }, []);

  const onChangeDate = (value) => {
    setSelectedDate(() => value);
    dispatch(
      fetchCustomerIntervalBandwidth({
        from: dayjs(value).valueOf(),
        to: dayjs(value).add(1, 'day').valueOf(),
        interval: 3600000,
      })
    );
  };

  return (
    <div className={styles.chart_page}>
      <Navbar />
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12} md={9}>
          <section className={styles.paper_group}>
            {isAdmin ? <AdminTotalStats /> : null}
            {/* <CustomerTotalStats /> */}
            <BasePaper
              title="Active streams"
              content={intervalTotalStatsOfSelectedCustomer.streams}
            />
            {/* 選取日的流量加總先由前端計算 */}
            {/* <BasePaper
              title="Bytes transfered"
              content={`${intervalTotalStatsOfSelectedCustomer.traffic}`}
            /> */}
            <BasePaper
              title="Bytes transfered"
              content={`${Math.round(
                intervalBandwidthOfSelectedCustomer.reduce((acc, curr) => {
                  acc = acc + curr.avg;
                  return acc;
                }, 0)
              )} MB`}
            />
            <BasePaper
              title="Customer balance"
              content={`$ ${intervalTotalStatsOfSelectedCustomer.balance.toFixed(2)}`}
            />
          </section>
        </Grid>
        <Grid item xs={12} md={3}>
          <section className={styles.section_customer_info}>
            <div className={styles.customer}>
              <h2 className={styles.customer_email}>
                {selectedCustomer.token ? `Current user: ${selectedCustomer.email}` : null}
              </h2>
              <div className={styles.customer_token}>
                <h2 className={styles.customer_token_title}>Customer token:</h2>
                <CopyBoard content={selectedCustomer.token ? selectedCustomer.token : token} />
              </div>
            </div>
          </section>
        </Grid>
      </Grid>
      <section className={styles.section_bandwidth}>
        <div className={styles.date_picker}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              variant="inline"
              label="Date"
              format="yyyy/MM/dd"
              value={selectedDate}
              onChange={onChangeDate}
              animateYearScrolling
              disableFuture
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className={styles.bandwidth_chart}>
          <BandwidthChart data={intervalBandwidthOfSelectedCustomer} />
        </div>
      </section>
    </div>
  );
};

export default CustomerCharts;
