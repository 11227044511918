import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';

import TabPanel from './TabPanel';
import CreateCustomerForm from '../CreateCustomerForm';
import BaseModal from '../BaseModal';
import CustomersAccountTable from '../CustomersAccountTable';
import DeployedServersTable from '../DeployedServersTable';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const TableTabs = () => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Customers Account" />
          <Tab label="Deployed Servers" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {value === 0 ? (
          <>
            <Button variant="contained" onClick={() => setIsOpen(true)}>
              New Customer
            </Button>
            <BaseModal isOpen={isOpen} handleClose={() => setIsOpen(false)}>
              <CreateCustomerForm handleClose={() => setIsOpen(false)} />
            </BaseModal>
            <CustomersAccountTable />
          </>
        ) : null}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {value === 1 ? <DeployedServersTable /> : null}
      </TabPanel>
    </div>
  );
};

export default TableTabs;
