export const FETCH_CUSTOMER_LIST = 'FETCH_CUSTOMER_LIST';
export const FETCH_DEPLOYED_SERVER_LIST = 'FETCH_DEPLOYED_SERVER_LIST';
export const FETCH_TOTAL_STATUS = 'FETCH_TOTAL_STATUS';

export const FETCH_CUSTOMER_INTERVAL_BANDWIDTH = 'FETCH_CUSTOMER_INTERVAL_BANDWIDTH';
export const FETCH_CUSTOMER_TOTAL_STATS = 'FETCH_CUSTOMER_TOTAL_STATS';

export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const SELECT_CUSTOMER = 'SELECT_CUSTOMER';
export const RESET_SELECTED_CUSTOMER = 'RESET_SELECTED_CUSTOMER';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const CHECK_AUTH = 'CHECK_AUTH';

export const SET_SNACKBAR = 'SET_SNACKBAR';
export const SET_IS_LOADING = 'SET_IS_LOADING';
