import {
  FETCH_CUSTOMER_LIST,
  SELECT_CUSTOMER,
  DELETE_CUSTOMER,
  FETCH_CUSTOMER_INTERVAL_BANDWIDTH,
  FETCH_CUSTOMER_TOTAL_STATS,
  RESET_SELECTED_CUSTOMER,
  SET_IS_LOADING,
} from './actionTypes';
import adminBackend from '../utils/backendAPI/admin';
import customerBackend from '../utils/backendAPI/customer';
import history from '../history';

export const fetchCustomerList = () => async (dispatch) => {
  // 先暫時 100 筆，之後需改成動態
  const response = await adminBackend.GET('/admin/customer?skip=0&limit=100');

  dispatch({ type: FETCH_CUSTOMER_LIST, payload: response });
};

export const createCustomer = (formData) => async (dispatch) => {
  await adminBackend.PUT('/admin/customer', { customer: formData });

  dispatch(fetchCustomerList());
};

export const editCustomer = ({ id, customer }) => async (dispatch) => {
  await adminBackend.POST('/admin/customer', { _id: id, customer: customer });

  dispatch(fetchCustomerList());
};

/* 
目前後端 api: admin 欲查看特定 customer 的資訊，
需要先「幫該 customer 登入」獲取該 customer 的 token 
*/
export const selectCustomer = (data) => async (dispatch) => {
  const { email, password } = data;
  try {
    const response = await adminBackend.GET('/auth', { email, password });
    dispatch({ type: SELECT_CUSTOMER, payload: { ...data, ...response } });
    history.push('/chart');
  } catch (error) {
    return Promise.reject(error);
  }
};

/* 返回 admin 頁面時，清空之前所選取的 customer */
export const resetSelectedCustomer = (data) => {
  return { type: RESET_SELECTED_CUSTOMER };
};

export const deleteCustomer = (id) => async (dispatch) => {
  try {
    await adminBackend.DELETE(`/admin/customer/${id}`);
    dispatch({ type: DELETE_CUSTOMER });
    dispatch(fetchCustomerList());
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchCustomerIntervalBandwidth = (data) => async (dispatch) => {
  const response = await customerBackend.GET('/customer/stats/bandwidth', data);

  dispatch({ type: FETCH_CUSTOMER_INTERVAL_BANDWIDTH, payload: response.stats });
};

export const fetchCustomerTotalStats = (data) => async (dispatch) => {
  const response = await customerBackend.GET('/customer/stats/total', data);

  dispatch({ type: FETCH_CUSTOMER_TOTAL_STATS, payload: response });
};
