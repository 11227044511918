import axios from 'axios';
import store from '../../store';

// const baseURL = 'https://ken-admin.meshstream.io/api';
const baseURL = 'https://streaming-admin.meshstream.io/api';

const backend = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

backend.interceptors.request.use(
  function (config) {
    const token = store.getState().auth.token;

    if (token) {
      config.headers.Authorization = `bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default {
  async POST(url, body, config) {
    try {
      const response = await backend.post(url, body, config);
      return response.data.config;
    } catch (error) {
      throw new Error(error.data);
    }
  },
};
