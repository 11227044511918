import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { setSnackbar } from '../../actions/snackbar';

import Button from '@material-ui/core/Button';

import styles from './index.module.scss';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiButton-root': {
      display: 'block',
      marginTop: '5px',
      marginLeft: 'auto',
      fontFamily: 'inherit',
    },
  },
}));

const CopyBoard = ({ content }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onCopyHandler = () => {
    dispatch(
      setSnackbar({
        isSnackbarOpen: true,
        snackbarType: 'success',
        snackbarMessage: 'Successfully copied',
      })
    );
  };

  return (
    <div className={classes.root}>
      <div className={styles.content}>{content}</div>
      <CopyToClipboard text={content}>
        <Button variant="contained" onClick={onCopyHandler}>
          Copy
        </Button>
      </CopyToClipboard>
    </div>
  );
};

export default CopyBoard;
