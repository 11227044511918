import dayjs from 'dayjs';
import {
  FETCH_CUSTOMER_LIST,
  CREATE_CUSTOMER,
  SELECT_CUSTOMER,
  FETCH_CUSTOMER_INTERVAL_BANDWIDTH,
  FETCH_CUSTOMER_TOTAL_STATS,
  RESET_SELECTED_CUSTOMER,
} from '../actions/actionTypes';

// 預設日期為當天
const millisecondTimestampAtStartOfToday = dayjs().startOf('day').valueOf();
const millisecondTimestampAtEndOfToday = dayjs().endOf('day').valueOf();
const hourInterval = 3600000;

const initialState = {
  customerList: {
    fields: [],
    customers: [],
  },
  selectedCustomer: {},
  selectedDates: {
    from: millisecondTimestampAtStartOfToday,
    to: millisecondTimestampAtEndOfToday,
    interval: hourInterval,
  },
  intervalBandwidthOfSelectedCustomer: [],
  intervalTotalStatsOfSelectedCustomer: {
    streams: 0,
    traffic: 0,
    balance: 0,
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CUSTOMER_LIST: {
      return { ...state, customerList: { ...payload } };
    }

    case CREATE_CUSTOMER: {
      return { ...state };
    }

    case SELECT_CUSTOMER: {
      return { ...state, selectedCustomer: { ...state.selectedCustomer, ...payload } };
    }

    case RESET_SELECTED_CUSTOMER: {
      return {
        ...state,
        selectedCustomer: {},
        intervalBandwidthOfSelectedCustomer: [],
        intervalTotalStatsOfSelectedCustomer: {
          streams: 0,
          traffic: 0,
          balance: 0,
        },
      };
    }

    case FETCH_CUSTOMER_INTERVAL_BANDWIDTH: {
      const newPayload = payload.map((item) => {
        return { ...item, date: `${dayjs(item.date).get('hour')}:00`, avg: item.avg / 1000 };
      });

      return { ...state, intervalBandwidthOfSelectedCustomer: [...newPayload] };
    }

    case FETCH_CUSTOMER_TOTAL_STATS: {
      return { ...state, intervalTotalStatsOfSelectedCustomer: payload };
    }

    default: {
      return state;
    }
  }
};
