import { FETCH_DEPLOYED_SERVER_LIST, FETCH_TOTAL_STATUS } from './actionTypes';
import backend from '../utils/backendAPI/admin';

export const fetchDeployedServerList = () => async (dispatch) => {
  const { servers } = await backend.GET('/admin/stats/by-ip');

  dispatch({ type: FETCH_DEPLOYED_SERVER_LIST, payload: servers });
};

export const fetchTotalStats = () => async (dispatch) => {
  const status = await backend.GET('/admin/stats/total');

  dispatch({ type: FETCH_TOTAL_STATUS, payload: status });
};
