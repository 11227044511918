import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { createCustomer } from '../../actions/customer';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const md5 = require('md5');

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: '8px 0',
    },
    '& .MuiFormLabel-root': {
      fontSize: '14px',
    },
    '& button': {
      display: 'block',
      margin: '30px auto 0',
    },
    '& h1': {
      textAlign: 'center',
    },
  },
}));

const validationSchema = yup.object({
  name: yup.string().required('Password is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  password: yup.string().required('Password is required'),
  balance: yup.number().required('Balance is required'),
});

const CreateCustomerForm = ({ handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      balance: '',
      remark: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formikData = JSON.parse(JSON.stringify(values));

      dispatch(createCustomer({ ...formikData, password: md5(formikData.password) }));
      handleClose();
      formik.handleReset();
    },
  });

  return (
    <form className={classes.root} autoComplete="off" onSubmit={formik.handleSubmit}>
      <h1>ADD CUSTOMER</h1>
      <TextField
        fullWidth
        id="name"
        name="name"
        label="Name"
        value={formik.values.name}
        error={formik.touched.name && Boolean(formik.errors.name)}
        onChange={formik.handleChange}
      />
      <TextField
        fullWidth
        id="email"
        name="email"
        label="Email"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
      <TextField
        fullWidth
        id="password"
        name="password"
        label="Password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
      />
      <TextField
        fullWidth
        id="balance"
        name="balance"
        label="balance"
        type="number"
        value={formik.values.balance}
        onChange={formik.handleChange}
        error={formik.touched.balance && Boolean(formik.errors.balance)}
        helperText={formik.touched.balance && formik.errors.balance}
      />
      <TextField
        fullWidth
        id="remark"
        name="remark"
        label="remark"
        type="text"
        value={formik.values.remark}
        onChange={formik.handleChange}
        error={formik.touched.remark && Boolean(formik.errors.remark)}
        helperText={formik.touched.remark && formik.errors.remark}
      />
      <Button color="primary" variant="contained" type="submit">
        Create Customer
      </Button>
    </form>
  );
};

export default CreateCustomerForm;
