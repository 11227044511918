import React from 'react';

import styles from './index.module.scss';

const LatencySignal = ({ currentRoundTripTime, jitterBufferDelayRate }) => {
  const level = (data) => {
    if (data < 1) return 'save';
    if (data >= 1 && data <= 3) return 'warning';
    if (data <= 3) return 'save';
  };

  return (
    <div className={styles.latency_signal}>
      <p className={styles[level(currentRoundTripTime)]}>
        Network Latency: {currentRoundTripTime ? `${currentRoundTripTime} s` : null}
      </p>
      {jitterBufferDelayRate ? (
        <p className={styles[level(jitterBufferDelayRate)]}>
          Media Latency: {jitterBufferDelayRate ? `${jitterBufferDelayRate.toFixed(3)} s` : null}
        </p>
      ) : null}
      <div className={styles.hint}>
        <p className={styles.save}>{`< 1 s`}</p>
        <p className={styles.warning}>{`1 ~ 3 s`}</p>
        <p className={styles.danger}>{`> 3 s`}</p>
      </div>
    </div>
  );
};

export default LatencySignal;
