import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import AuthButton from '../AuthButton';
import BurgerIcon from '../../images/menu.png';
import styles from './index.module.scss';

const Navbar = () => {
  const { isAdmin, token } = useSelector((state) => state.auth);
  const [isBurgerMenu, setIsBurgerMenu] = useState(false);

  const toggleBergerMenu = (status) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setIsBurgerMenu(() => status);
  };

  return (
    <nav className={styles.nav}>
      <div className={styles.brand}>MeshStream</div>
      <div className={styles.nav_items}>
        <Link to="/">Streamer</Link>
        <Link to="/viewer">Viewer</Link>
        {isAdmin ? <Link to="/admin">Admin</Link> : null}
        {!isAdmin && token ? <Link to="/chart">Stats</Link> : null}
        <AuthButton />
      </div>

      <div className={styles.burger_menu}>
        <Button onClick={toggleBergerMenu(true)}>
          <img className={styles.burger_icon} src={BurgerIcon} alt="" />
        </Button>
        <SwipeableDrawer
          anchor="right"
          open={isBurgerMenu}
          onClose={toggleBergerMenu(false)}
          onOpen={toggleBergerMenu(true)}
        >
          <div className={styles.burger_nav_items}>
            <Link to="/">Streamer</Link>
            <Link to="/viewer">Viewer</Link>
            {isAdmin ? <Link to="/admin">Admin</Link> : null}
            {!isAdmin && token ? <Link to="/chart">Stats</Link> : null}
            <AuthButton />
          </div>
        </SwipeableDrawer>
      </div>
    </nav>
  );
};

export default Navbar;
