// the type of value can only be a string, a number
const RESOLUTION_OPTIONS = [
  {
    key: '0',
    text: '3840 x 2160',
    value: 0,
  },
  {
    key: '1',
    text: '1920 x 1080',
    value: 1,
  },
  {
    key: '2',
    text: '1280 x 720',
    value: 2,
  },
  {
    key: '3',
    text: '320 x 240',
    value: 3,
  },
  {
    key: '4',
    text: '80 x 60',
    value: 4,
  },
];

const RESOLUTIONS = [
  { width: 3840, height: 2160 },
  { width: 1920, height: 1080 },
  { width: 1280, height: 720 },
  { width: 320, height: 240 },
  { width: 80, height: 60 },
];

export { RESOLUTION_OPTIONS, RESOLUTIONS };
