import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer,
} from 'recharts';

import styles from './index.module.scss';

const BandwidthChart = ({ data }) => {
  const barFormatter = (value) => {
    if (value === 0) return '';
    return Math.round(value) + 'MB';
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.chart_title}>Bandwidth</h1>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width="500px"
          height="300px"
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          barSize={20}
        >
          <XAxis dataKey="date" scale="point" padding={{ left: 10, right: 10 }} />
          <YAxis width={25} height={100} label={{ value: 'MB', position: 'insideTop', dy: -35 }} />
          <Tooltip
            formatter={(value, name, props) => {
              return `${value} MB`;
            }}
          />
          <Legend verticalAlign="top" height={36} />
          <CartesianGrid strokeDasharray="3 3" />
          <Bar dataKey="avg" fill="#82ca9d">
            <LabelList dataKey="avg" position="top" formatter={barFormatter} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BandwidthChart;
