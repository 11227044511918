import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchCustomerList, selectCustomer, deleteCustomer } from '../../actions/customer';
import usePagination from '../../hooks/usePagination';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
// Icons
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/EditOutlined';
import Button from '@material-ui/core/Button';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';

import BaseModal from '../BaseModal';
import EditCustomerForm from '../EditCustomerForm';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    overflowX: 'auto',

    '& .MuiTableCell-root': {
      padding: '10px 0px 10px 20px',
      color: '#666',
    },
    '& th.MuiTableCell-root': {
      fontSize: 15,
      fontWeight: 700,
    },
    '& td.MuiTableCell-root': {
      fontSize: 14,
    },
  },
}));

const CustomersAccountTable = ({ customerList, fetchCustomerList }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isEditCustomerFormOpen, setIsEditCustomerFormOpen] = useState(false);
  const [editCustomerId, setEditCustomerId] = useState('');
  const [page, rowsPerPage, handleChangeRowsPerPage, handleChangePage] = usePagination();

  useEffect(() => {
    fetchCustomerList();
  }, []);

  return (
    <Paper className={classes.root}>
      <Table aria-label="caption table">
        <TableHead>
          <TableRow>
            {customerList.fields.map((title) => (
              <TableCell key={title} align="left">
                {title}
              </TableCell>
            ))}
            <TableCell align="center">edit</TableCell>
            <TableCell align="center">dashboard</TableCell>
            <TableCell align="center">delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customerList.customers
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((customer) => (
              <TableRow key={customer._id}>
                {customerList.fields.map((key) => {
                  if (key === 'balance') {
                    return (
                      <TableCell key={key}>
                        {customer[key] !== null ? `$ ${customer[key].toFixed(2)}` : '-'}
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell key={key}>{customer[key] !== null ? customer[key] : '-'}</TableCell>
                  );
                })}
                <TableCell align="center">
                  <IconButton
                    aria-label="edit"
                    onClick={() => {
                      setIsEditCustomerFormOpen(true);
                      setEditCustomerId(customer._id);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    aria-label="dashboard"
                    onClick={() => dispatch(selectCustomer(customer))}
                  >
                    <ExitToAppOutlinedIcon />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      dispatch(deleteCustomer(customer._id));
                    }}
                  >
                    delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 15, 20]}
        component="div"
        count={customerList.customers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <BaseModal
        isOpen={isEditCustomerFormOpen}
        handleClose={() => setIsEditCustomerFormOpen(false)}
      >
        <EditCustomerForm
          customerId={editCustomerId}
          handleClose={() => setIsEditCustomerFormOpen(false)}
        />
      </BaseModal>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return { customerList: state.customer.customerList };
};

export default connect(mapStateToProps, {
  fetchCustomerList,
})(CustomersAccountTable);
