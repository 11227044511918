import { FETCH_DEPLOYED_SERVER_LIST, FETCH_TOTAL_STATUS } from '../actions/actionTypes';

const initialState = {
  deployedServerList: [],
  totalStats: {
    streams: 0,
    bandwidth: 0,
    cpu: 0,
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_DEPLOYED_SERVER_LIST: {
      return {
        ...state,
        deployedServerList: [...payload].sort((a, b) => {
          return b.bandwidth - a.bandwidth;
        }),
      };
    }

    case FETCH_TOTAL_STATUS: {
      return { ...state, totalStats: payload };
    }

    default: {
      return state;
    }
  }
};
