import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetSelectedCustomer } from '../../actions/customer';

import Navbar from '../../components/Navbar';
import TableTabs from '../../components/TableTabs';
import AdminTotalStats from '../../components/AdminTotalStats';

import styles from './index.module.scss';

const Admin = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetSelectedCustomer());
  }, []);
  return (
    <div className={styles.admin}>
      <header>
        <Navbar />
      </header>
      <main>
        <AdminTotalStats />
        <section className={styles.section_tables}>
          <TableTabs />
        </section>
      </main>
    </div>
  );
};

export default Admin;
