import { SET_SNACKBAR } from '../actions/actionTypes';

const initialState = {
  isSnackbarOpen: false,
  snackbarType: 'success',
  snackbarMessage: '',
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_SNACKBAR: {
      return { ...state, ...payload };
    }

    default: {
      return state;
    }
  }
};
