import React, { useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTotalStats } from '../../actions/server';

import BasePaper from '../../components/BasePaper';

import styles from './index.module.scss';

const AdminTotalStats = () => {
  const dispatch = useDispatch();
  const totalStats = useSelector((state) => state.server.totalStats);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(fetchTotalStats());
    }, 0);

    const intervalId = setInterval(() => {
      dispatch(fetchTotalStats());
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
      clearInterval(intervalId);
    };
  }, []);

  return (
    <section className={styles.section_total_stats}>
      {totalStats ? (
        <>
          <BasePaper title="Streams published now" content={totalStats.streams} />
          <BasePaper title="Average cpu load on all servers" content={`${totalStats.cpu} %`} />
          <BasePaper
            title="Total bandwidth usage"
            content={`${totalStats.bandwidth.toFixed(2)}M bit/sec`}
          />
        </>
      ) : null}
    </section>
  );
};

export default AdminTotalStats;
