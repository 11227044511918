import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import usePagination from '../../hooks/usePagination';

import { fetchDeployedServerList } from '../../actions/server';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    marginLeft: 'auto',
    marginRight: 'auto',
    overflowX: 'auto',

    '& .MuiTableCell-root': {
      padding: '10px 0px 10px 20px',
      color: '#666',
    },
    '& th.MuiTableCell-root': {
      fontSize: 15,
      fontWeight: 700,
    },
    '& td.MuiTableCell-root': {
      fontSize: 14,
    },
  },
}));

const CustomersAccountTable = ({ deployedServerList, fetchDeployedServerList }) => {
  const classes = useStyles();
  const [page, rowsPerPage, handleChangeRowsPerPage, handleChangePage] = usePagination();

  useEffect(() => {
    fetchDeployedServerList();

    const intervalId = setInterval(() => {
      fetchDeployedServerList();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Paper className={classes.root}>
      <Table aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Ip</TableCell>
            <TableCell align="left">CPU Load</TableCell>
            <TableCell align="left">Bandwidth Load</TableCell>
            <TableCell align="left">Streams Published</TableCell>
            {/* <TableCell align="center">Drain</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {deployedServerList
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((customer) => (
              <TableRow key={customer._id}>
                <TableCell>{customer.ip}</TableCell>
                <TableCell>{customer.cpu} %</TableCell>
                <TableCell align="left">{customer.bandwidth.toFixed(2)}M bit/sec</TableCell>
                <TableCell>{customer.streams}</TableCell>
                {/* <TableCell align="center">
                  <Checkbox />
                </TableCell> */}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 15, 20]}
        component="div"
        count={deployedServerList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return { deployedServerList: state.server.deployedServerList };
};

export default connect(mapStateToProps, {
  fetchDeployedServerList,
})(CustomersAccountTable);
